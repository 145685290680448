import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import jnbIcon from "../images/goz&me.png";

function AboutPage() {
  return (
    <Layout>
      <SEO
        title="About"
        keywords={[`RBII`, `Sonoma`, `Richard Blanchard`, `John Blanchard`, `about`, `bio`, `experience`, `team`]}
      />

      <section className="text-center">
        
        <a
          href="https://jnblancha.com"
        >
          <h2 className="bg-yellow-400 text-2xl font-bold inline-block p-3">
            John Blanchard, Developer
          </h2>
        </a>

        <div style={{height:15}}/>

        <p className="leading-loose">
          It's easy for me to think about a world without electricity. 
          But I can't imagine a world without my father.
          I am a second generation engineer because I want to be my dad.
          The last few years I spent working on mobile smart cameras and photo editors. 
          These apps had wide ranging variations in success.
          Through these and other prototypes and releases; I've hardened a set of principals for software development that I wish to share.
          There isn't a corner of development that I don't own, I design brands, mockups, and copy.
          I've made vanilla iPhone apps for the past 6 years, but today I'll use Flutter.
          It took me the last week but I learned React and Gatsby, then wrote a few static websites.
          I am looking forward to using React Native for my next mobile app, and finding a web project to leverage Nextjs.
        </p>

        <div style={{height:15}}/>

        <img
          src={jnbIcon}
          className="block mx-auto"
          alt="goz&jnb"
        />

      </section>

    </Layout>
  );
}

export default AboutPage;
